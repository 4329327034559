@import "../../assets/style/vars.scss";

.allBracelets-out-container {
  display: flex;
  flex-direction: column;
  background-color: $dark-color;
  height: auto;
  min-height: 100vh;
  .allBracelets-navbar {
    color: $logo-color;
    font-size: 30px;
    display: flex;
    width: 100%;
    padding: 15px 60px;
    border-bottom: 1px solid $logo-color;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .link-allBracelets-left-arrow {
      font-size: 20px;
      text-decoration: none;
      border-radius: 50%;
      padding: 5px;
      color: $logo-color;
      border: 2px solid $logo-color;
      cursor: pointer;
    }

    .link-allBracelets {
      font-size: 26px;
      text-decoration: none;
      color: $logo-color;
      cursor: pointer;
    }
  }

  .price-filter-show-btn {
    background-color: $logo-color;
    color: $dark-color;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    width: max-content;
    margin: auto;
  }

  .allBracelets-price-filter-container {
    padding: 10px 30px;
  }

  .allBracelets-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .allBracelets-paragraph-no-product {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px !important;
      font-size: 45px;
      text-align: center;
      margin-top: 50px;
      font-weight: 400;
      font-family: "Open Sans", sans-serif;
      font-optical-sizing: auto;
    }

    .allBracelets-card {
      background: #f0f0f0;
      color: $dark-color;
      border-radius: 8px;
      overflow: hidden;
      margin: 10px;
      padding: 10px 20px;
      width: 300px;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      position: relative;
      &:hover {
        box-shadow: 0 0 10px $logo-color, 0 0 20px $logo-color,
          0 0 30px $logo-color, 0 0 40px $logo-color;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
      &::before {
        top: -50px;
        left: -50px;
        background: radial-gradient(
          circle at 30% 30%,
          $logo-color,
          transparent
        );
        transform: scale(0);
      }
      &::after {
        bottom: -50px;
        right: -50px;
        background: radial-gradient(
          circle at 70% 70%,
          $logo-color,
          transparent
        );
        transform: scale(0);
      }
      &:hover::before,
      &:hover::after {
        transform: scale(1);
      }
      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 20px;
        transition: all 0.3s;
      }

      .allBracelets-details {
        text-align: center;
        margin-top: 10px;

        .price {
          font-size: 18px;
          letter-spacing: 8px;
          color: $logo-color;
          margin: 20px 0;
          font-weight: 600;
        }

        .title {
          color: $dark-color;
          background-color: transparent;
          text-align: center;
          font-weight: 700;
          font-size: 32px;
          letter-spacing: 5px;
          text-transform: uppercase;
          font-family: "Playfair Display", sans-serif;
        }

        .description {
          display: none;
          font-size: 1rem;
          color: $silver;
          margin: 5px 0;
        }
      }
    }
  }

  .allBracelets-footer {
    display: none;
    justify-content: end;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: linear-gradient(
      to right,
      $dark-color,
      rgba(255, 255, 255, 0.527)
    );

    span {
      margin-right: 20px;
      font-size: 22px;
    }
  }
}

@media (max-width: $mobile-bp) {
  .allBracelets-out-container {
    height: auto;
    .allBracelets-navbar {
      padding: 15px 35px !important;
    }
  }
  .allBracelets-container {
    gap: 10px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    .allBracelets-card {
      width: 80%;
      margin: auto;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        transform: none;
      }

      img {
        width: 80%;
        padding: 10px 20px;
        border-radius: 12px;
      }

      .allBracelets-details {
        .title {
          text-align: center;
          font-size: 1.2rem;
          margin-right: 0;
        }

        .description {
          display: none;
        }

        .price {
          display: block;
        }
      }
    }
  }
}
