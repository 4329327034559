@import '../../assets/style/vars.scss';


.allEarrings-out-container {
    display: flex;
    flex-direction: column;

    .allEarrings-navbar {
        color: $dark-color;
        font-weight: 700;
        font-size: 30px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background: linear-gradient($dark-color , $silver );
        margin-bottom: 30px;

        .link-allEarring-left-arrow {
            font-size: 30px;
            text-decoration: none;
            border-radius: 50%;
            padding: 5px;
            color: black;
            border: 2px solid $dark-color;
            cursor: pointer;
        }

        .link-allEarring-shooping-cart {
            font-size: 30px;
            text-decoration: none;
            color: black;
        }

        .pi-shopping-cart{
            font-size: 30px;
            cursor: pointer;
        }
    }

    .price-filter-show-btn {
        background-color: $dark-color;
        color: $logo-color;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;

    }

    .allEarring-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px 0;

        
        .allEarring-paragraph-no-product {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px !important;
            font-size: 45px;
            text-align: center;
            margin-top: 50px;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
            font-optical-sizing: auto;
        }

        .allEarring-card {
            background: $dark-color;
            border-radius: 8px;
            overflow: hidden;
            margin: 10px;
            padding: 15px;
            width: 400px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s;
            color: $silver;
            cursor: pointer;


            &:hover {
                transform: scale(1.05);
            }

            img {
                width: 100%;
                height: 380px;
                object-fit: cover;
                border-radius: 5px;
            }

            .allEarring-details {
                text-align: left;
                margin-top: 10px;

                .price {
                    font-size: 1.5rem;
                    color: $silver;
                    margin: 5px 0;
                    text-decoration: none;
                    display: flex;
                    font-weight: 400;
                    justify-content: center;
                }

                .title {
                    font-size: 30px;
                    font-weight: bold;
                    color: $silver;
                    margin: 5px 0;
                    display: flex;
                    justify-content: center;
                }

                .description {
                    font-size: 1rem;
                    color: $silver;
                    margin: 5px 0;
                    position: relative;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width : $mobile-bp) {
    .allEarring-container {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap;
        gap: 10px;

        .allEarring-card {
            width: 48% !important;
            height: 310px;
            margin: 0 !important;

            &:hover {
                transform: none;
            }

            img {
                height: 150px !important;
            }

            .allEarring-details {
                .title {
                    // text-align: center;
                    font-size: 20px !important;
                    margin-right: 0;
                }

                .description {
                    // display: none;
                }

                .price {
                    // display: none;
                }
            }
        }

    }
}

.allEarring-loading{
    height: 50vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.allEarrings-price-filter-container{
    padding: 0 10px;
}

.allEarring-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: linear-gradient(to right, $dark-color, rgba(255, 255, 255, 0.527));

    span {
        margin-right: 20px;
        font-size: 22px;
    }
}