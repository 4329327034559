@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kapra+Neue&family=Brandon+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Brandon+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=KapraNeue&family=Brandon+Grotesque&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=KapraNeue&family=Brandon+Grotesque&display=swap");
@import url("https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "El Messiri", sans-serif; */
  font-family: "Poppins", sans-serif;
  /* direction: rtl; */
}
section {
  min-height: 100vh;
}

body {
  /* background-image: url('./page/image/Untitled-1back1\ \(1\).png') !important; */
  /* background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%); */
  font-family: "GE Hili", sans-serif;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
html {
  /* scroll-snap-type: y mandatory; */
  /* scroll-behavior: smooth; */
}
