@import "../../assets/style/vars.scss";

.cart-drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 35%;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1000; // Ensure it is on top of other elements
  overflow-y: auto;

  &.open {
    right: 0;
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;

    img {
      height: 40px;
    }
  }

  .close-button {
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }

  .cart-body {
    flex: 1;
    padding: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .no-items-message {
      text-align: center;
      padding: 20px;
      color: #666;
    }

    .cart-item {
      display: flex;
      margin-bottom: 20px;
      column-gap: 5px;
      padding-bottom: 12px;
      border-bottom: 2px solid $logo-color;
      .cart-images {
        width: 40%;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          margin-right: 20px;
        }
      }

      .cart-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .cart-details-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          width: 100%;

          h2 {
            margin: 0;
            font-size: 20px;
            color: #333;
          }

          p {
            margin: 0;
            font-size: 16px;
            color: #888;
            margin-left: 5px;
          }
        }

        .cart-details-description {
          margin: 0;
          font-size: 14px;
          color: #666;
          display: none;
        }

        .cart-quantity-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          //   width: 100%;
          justify-content: space-evenly;
          align-items: center;

          .cart-quantity-less-control {
            margin-right: 8px;
          }
          .cart-quantity-plus-control {
            margin-left: 8px;
          }
        }
      }
      .cart-delete-btn-container {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        width: 30%;
        align-items: center;
      }
    }
  }

  .cart-footer {
    padding: 20px 20px;
    background-color: #f1f1f1;
    border-top: 1px solid #ddd;

    .coupon-section {
      display: flex;
      margin-bottom: 20px;

      input {
        flex: 1;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      button {
        padding: 10px 20px;
        // margin-left: 10px;
        background-color: #333;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .checkout-section {
      text-align: center;

      .checkout-button {
        padding: 15px 30px;
        background-color: #333;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .cart-drawer {
    width: 100%; // Full width for mobile screens

    .cart-header {
      padding: 10px;

      img {
        // height: 30px;
      }
    }

    .cart-body {
      padding: 10px;

      .cart-item {
        flex-direction: row;
        align-items: center;

        .cart-images {
          img {
            width: 100%;
            // height: 60px;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }

        .cart-details {
          align-items: center;
          text-align: center;

          .cart-details-title {
            flex-direction: column;
            align-items: center;
          }

          h2 {
            font-size: 18px;
          }

          p {
            font-size: 14px;
          }

          .cart-details-description {
            font-size: 12px;
            display: none;
          }
        }
      }
    }

    .cart-footer {
      padding: 30px;

      .coupon-section {
        flex-direction: column;

        input {
          margin-bottom: 10px;
        }

        button {
          width: 100%;
        }
      }

      .checkout-section {
        .checkout-button {
          width: 100%;
          padding: 15px 20px;
          font-size: 18px;
        }
      }
    }
  }
}
