@import "../../assets/style/vars.scss";

.bracelets-out-container {
  background-color: $dark-color;
  text-align: center;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  //   &::before {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100% !important;
  //     height: 30px;
  //     z-index: 2;
  //     content: "";
  //     background: linear-gradient(
  //       to top,
  //       rgba(255, 255, 255, 0) 10%,
  //       $dark-color 200%
  //     );
  //   }
  //   &::after {
  //     position: absolute;
  //     left: 0;

  //     bottom: 0;
  //     width: 100% !important;
  //     height: 20px;
  //     z-index: 2;
  //     content: "";
  //     background: linear-gradient(
  //       to bottom,
  //       rgba(255, 255, 255, 0) 10%,
  //       $dark-color 200%
  //     );
  //   }
  .earring-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 45px;
    // margin-top: 30px;

    h2 {
      position: relative;
      text-align: center;
      align-items: center;
      font-size: 35px;
      color: $logo-color;
      border-radius: 30px;
      padding: 10px;
      margin-bottom: 0;
      text-shadow: $text-shadow;
      font-family: "KapraNeue", "Brandon Grotesque", sans-serif;
      text-transform: uppercase;
    }
    h2::before {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      left: -70px;
      bottom: 50%;
      background-color: $logo-color;
      border-radius: 50%;
    }
    h2::after {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      right: -70px;
      bottom: 50%;
      border-radius: 50%;
      background-color: $logo-color;
    }
  }
  h1 {
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 50px;
    color: $silver;
  }
}

@media (max-width: $mobile-bp) {
  .bracelets-out-container {
    padding-left: 0;
    padding-right: 0;
    // height: 100vh;
    scroll-snap-align: start;
    .earring-title {
      h2 {
        font-size: 22px;
      }
      h2::before {
        width: 40px;
        left: -40px;
      }
      h2::after {
        width: 40px;
        right:-40px;
      }
    }
    h1 {
      font-size: 30px;
      margin-top: 100px;
      margin-bottom: 40px;
    }
  }
}
