button {
  border: none;
  border-radius: 1rem;
  transition: all 0.5s linear;
  padding: 10px;
  // background-color: #ACB1D6;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #aaaaaa;
  color: white;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: -8px;
  width: 60px;
  top: -35px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
