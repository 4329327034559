@import "../../../assets/style/vars.scss";

.allBracelets-navbar {
  color: $logo-color;
  font-size: 30px;
  display: flex;
  width: 100%;
  padding: 15px 60px;
  border-bottom: 1px solid $logo-color;
  justify-content: space-between;
  align-items: center;
  //   margin-bottom: 30px;
  height: 80px;
  background-color: $dark-color;

  .pi-arrow-circle-left {
    font-size: 30px;
    text-decoration: none;
    border-radius: 50%;
    padding: 5px;
    color: $logo-color;
    // border: 2px solid $logo-color;
    cursor: pointer;
  }

  a {
    width: 50%;
    cursor: pointer;
    text-align: end;
    img {
      width: 30%;
    }
  }
}

@media (max-width: $mobile-bp) {
  .checkout-nav-bar-container {
    position: relative;

    div {
      position: absolute;
      top: 50px;
      left: 30px;
    }

    img {
      position: absolute;
      right: 60px;
      top: 0;
    }
  }
}
