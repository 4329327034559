@import "../../assets/style/vars.scss";

.home-out-container {
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
  background: $dark-color;
  //   background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
  position: relative;

  .home {
    // position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    // top: 34px;
    // border-radius: 20px;
    background-image: url("../../assets/image/photo_5877552136739472523_y.jpg");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: right;
    position: relative;
    z-index: 1;

    &::after {
      position: absolute;
      // left: 0;
      left: 0;
      bottom: 0;
      width: 100% !important;
      height: 80px;
      z-index: 2;
      content: "";
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        $dark-color 100%
      );
    }

    .home-left-details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      width: 65%;
      height: 100%;
      margin-bottom: 0;
      padding: 0 60px;
      margin-top: 50px;

      .home-left-img-logo {
        width: 60%;
        // width: 600px ;
        // height: 200px;
      }

      img {
        margin-bottom: 20px;
        border-radius: 50px;
      }

      h3 {
        font-size: 50px;
        font-family: "KapraNeue", "Brandon Grotesque", sans-serif;
        color: $logo-color;
      }
      .btn-shine {
        margin-bottom: 20px;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // padding: 12px 48px;
        color: $white-color;
        background: linear-gradient(
          to right,
          $logo-color 0,
          #fff 10%,
          $old-logo-color 20%
        );
        background-position: 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: shine 3s infinite linear;
        animation-fill-mode: forwards;
        -webkit-text-size-adjust: none;
        font-weight: 600;
        font-size: 22px;
        text-decoration: none;
        white-space: wrap;
        letter-spacing: 5px;
        // font-family: "Caveat";
        font-family: "Playfair Display";
        width: 100%;
        max-width: 100%;
      }
      @-moz-keyframes shine {
        0% {
          background-position: 0;
        }
        60% {
          background-position: 500px;
        }
        100% {
          background-position: 0;
        }
      }
      @-webkit-keyframes shine {
        0% {
          background-position: 0;
        }
        60% {
          background-position: 500px;
        }
        100% {
          background-position: 0;
        }
      }
      @-o-keyframes shine {
        0% {
          background-position: 0;
        }
        60% {
          background-position: 500px;
        }
        100% {
          background-position: 0;
        }
      }
      @keyframes shine {
        0% {
          background-position: 0;
        }
        60% {
          background-position: 500px;
        }
        100% {
          background-position: 0px;
        }
      }
      p {
        padding: 10px 20px;
        border-radius: 12px;
        text-align: start;
        color: $white-color;
        font-size: 16px;
        width: 80%;
        line-height: 1.5;
        font-family: "Playfair Display";
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
      }

      a {
        font-size: 20px;
        margin-top: 20px;
        background-color: $logo-color;
        padding: 10px 16px;
        border-radius: 10px;
        cursor: pointer;
        font-family: "Poppins", sans-serif;

        &:visited {
          color: $silver;
        }
      }
    }
  }
}

@media (max-width: 1900px) {
  .home {
    .home-left-details {
      h3 {
        font-size: 30px !important;
        text-align: start;
      }
      a {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: $tablet-bp) {
  .home-out-container {
    height: 100vh !important;
    scroll-snap-align: start;
  }

  .home {
    width: 100%;
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
    background-image: none !important;
    // position: relative;
    height: 100vh !important;

    img {
      // display: none;
      margin-bottom: 5px !important;
    }

    a {
      margin-top: 5px !important;
    }

    .home-left-details {
      display: flex !important;
      flex-direction: column !important;
      flex: 1;
      width: 100% !important;
      padding-top: 80px !important;
      position: absolute;
      margin-top: 0px !important;
      padding-bottom: 50px;
      margin-bottom: 0px !important;
      z-index: 1;

      &::before {
        position: absolute;
        // left: 0;
        top: 0;
        width: 100% !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      &::after {
        position: absolute;
        // left: 0;
        left: 0;
        bottom: 0;
        width: 430px !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      // .home-left-img-logo {
      //   width: 100% !important;
      //   height: 150px !important;
      //   // background-image: url('../../assets/image/girl_copy.png');
      //   background-repeat: no-repeat;
      //   background-size: cover;
      //   background-position: center;
      // }

      h3 {
        font-size: 20px !important;
        white-space: nowrap;
        margin-bottom: 15px;
      }

      a {
        font-size: 20px !important;
        color: $silver;
      }
    }

    .home-right-details {
      flex: 2;
      width: 100% !important;
      // height: 400px !important;
      background-image: url("../../assets/image/home_background_newest.jpg") !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 40% 0% !important;
      background-position: center;
      position: relative;

      img {
        width: 100% !important;
        height: 320px;
      }

      &::after {
        position: absolute;
        // left: 0;
        left: 0;
        bottom: 0;
        width: 430px !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      // border-radius: 5px;
      // margin-bottom: 20px;
    }
  }
}

@media (max-width: $mobile-bp) {
  .home-out-container {
    height: 100vh !important;
  }

  .home {
    width: 100%;
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
    background-image: none !important;
    // position: relative;
    height: 100vh !important;

    img {
      // display: none;
      margin-bottom: 5px !important;
    }

    a {
      margin-top: 20px !important;
    }

    .home-left-details {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      flex: 1;
      width: 100% !important;
      padding: 0 0px !important;
      padding-top: 80px !important;
      position: absolute;
      margin-top: 0px !important;
      padding-bottom: 50px;
      margin-bottom: 0px !important;
      z-index: 1;

      &::before {
        position: absolute;
        // left: 0;
        top: 0;
        width: 100% !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      &::after {
        position: absolute;
        // left: 0;
        left: 0;
        bottom: 0;
        width: 430px !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      img {
        display: none;
        width: 350px !important;
        height: 150px !important;
        object-fit: contain;
        border-radius: 10px !important;
        // background-image: url('../../assets/image/girl_copy.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
      }
      .btn-shine {
        width: 90% !important;
        max-width: 90% !important;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
      }
      h3 {
        font-size: 25px !important;
        white-space: nowrap;
        text-align: center;
        margin-bottom: 10px !important;
        padding: 20px 10px;
      }
      p {
        display: block;
        width: 90% !important;
        max-width: 90% !important;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 200px;
        font-size: 16px !important;
        // color:$dark-color !important;
        padding: 10px 20px !important;
        // background-color: rgba(0, 0, 0, 0.5);
        // backdrop-filter: blur(10px);
      }

      a {
        font-size: 18px !important;
        color: $dark-color;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .home-right-details {
      flex: 2;
      width: 100% !important;
      // height: 400px !important;
      background-image: linear-gradient(
          180deg,
          rgba(19, 0, 33, 0.06) 76%,
          #171815 120%
        ),
        url("../../assets/image/photo_5877552136739472523_y.jpg") !important;
      // background-image:     url('../../assets/image/home_background_newest.jpg') !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0 !important;
      background-position: center;
      position: relative;

      img {
        width: 100% !important;
        height: 320px;
      }

      &::after {
        position: absolute;
        // left: 0;
        left: 0;
        bottom: 0;
        width: 430px !important;
        height: 50px;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $dark-color 200%
        );
      }

      // border-radius: 5px;
      // margin-bottom: 20px;
    }
  }
}

@media (max-width: $mobile-bp) {
  .home-out-container {
    height: 100vh !important;
    scroll-snap-align: start;
  }
}
