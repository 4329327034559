@import "../../assets/style/vars.scss";
@import "../../assets/style/breakpoint.scss";

// General Header Styles
.header-container {
  &.rtl {
    direction: rtl;
  }

  &.ltr {
    direction: ltr;
  }
}

header {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s ease;
  position: fixed;
  color: $logo-color !important;
  background-color: transparent;

  &.scroll {
    backdrop-filter: blur(40px);
  }
}

.language-selector-desktop {
  display: none;
}

header .nav-bar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  transition: 0.3s ease;
  text-transform: uppercase;
  font-weight: 400;

  .nav-close-btn,
  .nav-menu-btn {
    display: none;
  }

  .logo-header {
    // margin-bottom: 15px;
    overflow: hidden;
    // margin-top: 15px;
    object-fit: contain;
    left: 0 !important;
    position: relative;
    top: -5px;
    cursor: pointer;
  }

  .header-cart {
    .pi-shopping-cart {
      font-size: 25px;
    }
  }

  header .nav-bar .Logo {
    color: $logo-color;
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // Desktop Navigation Styles
  header .nav-bar .navigation {
    display: flex; // Default to flex on larger screens
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent; // No background
    box-shadow: none; // No shadow
    transform: none; // No transform
    opacity: 1; // Fully opaque
    overflow: visible; // No overflow restriction
    flex-direction: row; // Horizontal layout
    z-index: auto; // Normal stacking order
    transition: none; // No transition
  }
}

.nav-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;

  .nav-link {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .nav-item-left {
    flex: 10 !important;
    display: flex;
    gap: 20px;
  }

  .language-selector-mobile {
    flex: 1 !important;
    margin: 15px;
  }
}

header .nav-bar .navigation .nav-item a {
  color: $white-color;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  // font-weight: bold;
}

header .nav-bar .navigation .nav-item a:hover,
header .nav-bar .navigation .nav-item a.active {
  color: $logo-color;
  cursor: pointer;
}
header .nav-bar .navigation .nav-item a.active::after {
  position: absolute;
  content: "";
  background-color: $logo-color;
  bottom: -10px;
  border-radius: 50%;
  left: 50%;
  transform: translate(0, -45%);
  width: 5px;
  height: 5px;
}

.language-selector-mobile {
  display: flex;
  gap: 10px;
  margin: 20px;
  align-items: center;
}

.language-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: $dark-color;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none;
  border: none;
}

.language-btn.active {
  background-color: $logo-color; // Replace with your primary color variable
  color: white;
}

.language-btn:hover {
  background-color: $dark-color; // Replace with your dark color variable
  color: white;
}

.language-selector-desktop {
  display: none;
}

.Login-place {
  display: none !important;
}

// Media query to hide desktop selector on mobile and show mobile selector

// Mobile Navigation Styles
@media (max-width: 1200px) {
  .header-container {
    &.rtl {
      direction: ltr;
    }

    &.ltr {
      direction: ltr;
    }
  }

  header {
    height: 80px;

    .nav-bar {
      display: flex;
      width: 100%;
      padding: 10px;
      margin: 30px 0;
      align-items: center;
      justify-content: center;

      .logo-header {
        position: relative !important;
        height: 100px !important;
      }

      .Login-place {
        display: flex !important;
        font-size: 25px;
        margin-right: 10px;
        position: absolute;
        right: 0 !important;
        cursor: pointer;
      }

      .header-cart {
        font-size: 23px;
        position: absolute;
        left: 10px; // Align to the left
        top: 50%;
        transform: translateY(-50%);

        .pi-shopping-cart {
          font-size: 22px;
        }
      }
    }
  }

  header .nav-bar .navigation {
    display: none; // Hidden by default on mobile
    position: fixed;
    top: 75px; // Below the header
    left: 0;
    width: 100%;
    height: auto; // Half viewport height
    background-image: url("../../assets/image/girl_copy.png") !important;
    // background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 10000;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-100%); // Initially off-screen
    opacity: 0;
  }

  header .nav-bar .navigation {
    display: none;
    /* Initially hidden */
    opacity: 0;
    /* Start with zero opacity */
    transform: translateY(-100%);
    /* Position above the view */
    transition: all 0.5s ease-in-out;
    /* Smooth transition */
  }

  header .nav-bar .navigation.active {
    // position: static !important;
    display: flex;
    opacity: 1;
    z-index: 10000 !important;
    transform: translateY(0);
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    /* Fallback for non-supporting browsers */
    -webkit-backdrop-filter: blur(10px);
    /* For Safari */
    backdrop-filter: blur(40px);
    /* For other browsers */
  }

  header .nav-bar .navigation .nav-item {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    overflow-x: auto;
    /* Use overflow-x to scroll horizontally */
  }

  header .nav-bar .navigation .nav-item .nav-item-left {
    flex: 1;
    padding: 20px;

    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    /* Center items */
  }

  header .nav-bar .navigation .nav-item .language-selector-mobile {
    flex: 1;
  }

  header .nav-bar .navigation .nav-link {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  header .nav-bar .navigation.active {
    display: flex;
    animation: slideInFromTop 0.5s ease-in-out forwards;
  }

  header .nav-bar .navigation .nav-item a {
    margin: 10px 0;
  }

  .nav-close-btn {
    display: block;
    color: var(--dark-color);
    font-size: 30px;
    cursor: pointer;
    top: 10px;
    right: 10px;
  }

  .nav-menu-btn {
    display: block;
    color: var(--white-color);
    font-size: 30px;
    cursor: pointer;
    margin-left: 10px;
  }

  .navlink-for-cart {
    font-size: 20px;
    position: absolute;
    left: 20px;
    top: 35px;
  }

  .language-selector-desktop {
    display: none;
  }

  .language-selector-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin: 20px;
    align-items: center;
  }

  .language-btn {
    width: 100%;
    height: 100%;
    padding: 10px 20px; // Adjust padding for better touch experience on mobile
  }

  .language-btn:hover {
    background-color: $logo-color !important; // Replace with your dark color variable
    color: white;
  }
}
