@import "../../assets/style/vars.scss";

.view-container {
  //   height: calc(100vh - 0px);
  background-color: $dark-color;
  &.rtl {
    background-color: $dark-color;
    direction: rtl;
  }

  &.ltr {
    direction: ltr;
    background-color: $dark-color;
  }
}
@media (max-width: 767px) {
  .view-container {
    height: auto;
    min-height: 100vh;
  }
}

.view-out-container {
  .swiper-pagination-bullet {
    background-color: $logo-color !important;
    width: 12px;
    height: 12px;
  }
  color: $dark-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .view-navbar {
    padding: 10px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
    // height: 13vh;
    border-bottom: 1px solid $logo-color;
    // margin-bottom: 50px;

    img {
      width: 100%;
      //   height: 120px;
    }
    a {
      width: 15%;
    }
    @media (max-width: 767px) {
      .view-out-container {
        min-height: 100vh;
        height: auto;
      }
      .view-navbar {
        padding: 20px 30px;
      }
      a {
        width: 50%;
      }
    }
    .back-from-view-container {
      //   position: absolute;
      //   left: 0;
      //   margin-left: 50px;
      //   margin-top: 10px;
    }

    .view-cart-btn {
      //   position: absolute;
      font-size: 30px;
      color: $logo-color;
      cursor: pointer;
    }
  }

  .view-loading {
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-container {
    display: flex;
    height: 100%;
    width: 90%;
    margin: auto;
    .view-images {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
      padding: 12px;
      .left {
        height: 100%;
        display: flex;
        flex-direction: column;
        // flex: 0.5;
        align-items: center;
        justify-content: center;
        // gap: 25px;
        img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          // border-radius: 12px;
          border: 1px solid #e7bb67;
        }
      }
      .right {
        flex: 1;
        height: 480px;
        width: 480px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        // margin: auto;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
          border: 1px solid #e7bb67;
          border-radius: 0;
        }
      }
      h2 {
        font-size: 5px;
        text-align: center;
      }
      img {
        width: 400px;
        height: 400px;
        object-fit: cover;
        // border-radius: 12px;
        // border: 2px solid $logo-color;
      }
    }
    .view-details-container {
      display: flex;
      flex-direction: column;
      padding: 15px !important;
      justify-content: center;
      .view-details-title {
        border-bottom: none !important;
        h2 {
          margin-bottom: 20px;
          font-family: "KapraNeue";
          letter-spacing: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-family: "normal";
            color: $white-color;
            // font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .view-details-description {
        border: 1px solid $logo-color;
        padding: 10px 5px;
        position: relative;
        color: $white-color;
        margin-bottom: 20px !important;
        text-align: center;
        border-radius: 5px;
      }
      .view-details-description::before {
        position: absolute;
        content: "Description";
        font-size: 12px;
        top: -10px;
        left: 10px;
        padding: 0 5px;
        color: $silver;
        background-color: $dark-color;
      }
      .view-details-color-container {
        color: #fff;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        .view-details-colors {
          width: 5%;
        }
      }
    }
    // .bg-slider-in-view {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   padding: 35px;
    //   // background-color: red;
    // }

    // .swiper-backface-hidden .swiper-slide {
    //   width: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   text-align: center;
    //   margin-bottom: 10px;
    // }

    // .view-out-container .view-container .view-images img {
    //   border: none;
    // }

    // .view-img-of-swiper {
    //   border-radius: 20px;
    //   object-fit: fill !important;
    //   cursor: pointer;
    // }

    // .view-img-of-swiper-details {
    //   cursor: pointer;
    //   // border-radius: 20px;
    // }

    .view-details-container {
      flex: 1.2;
      padding: 50px;

      .view-details-title {
        // height: 100px;

        h2 {
          font-size: 30px;
          font-weight: bold;
          color: $logo-color;
        }

        p {
          font-size: 20px;
          margin-bottom: 5px;
        }

        .view-details-description {
          font-size: 15px;
          margin-bottom: 5px;
        }

        border-bottom: 1px solid white;
      }

      // .view-details-sizes {
      //     // height: 100px;

      //     p {
      //         margin-top: 20px;
      //         color: $logo-color;
      //         font-weight: 300;
      //         margin-bottom: 15px;

      //         a {
      //             color: $logo-color;
      //         }
      //     }
      // }

      .view-details-color-container {
        // color: $white-color;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-around;
        // align-items: center;
        // margin-bottom: 20px;
        // margin-top: 20px;

        .view-details-colors {
          display: flex;
          gap: 10px;
          align-items: center;

          .p-component,
          .p-component * {
            opacity: 1;
          }
        }
      }

      span {
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }

      .add-to-bag-button {
        width: 100%;
        margin-top: 30px;
        text-align: center;
      }
    }
  }
  @media (max-width: 767px) {
    .view-out-container {
      min-height: 100vh !important;
      height: auto !important;
    }
    .view-navbar {
      padding: 20px 30px;
    }
    a {
      width: 50%;
    }
    .view-container {
      flex-direction: column;
    }
    .view-images {
      width: 100% !important;
      flex-direction: column-reverse;
      .p-image-preview-container:hover > .p-image-preview-indicator {
        background-color: transparent !important;
      }
      .p-image-preview-indicator {
        background-color: transparent;
      }
      .p-image-action,
      .p-link {
        background-color: transparent !important;
      }
      .left {
        height: max-content !important;
        flex-direction: row !important;
        align-items: flex-start !important;
        img {
          width: 86px !important;
          height: 86px !important;
        }
      }
      .right {
        flex: 1 !important;
        height: 300px !important;
        width: 344px !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .view-out-container {
    height: auto;
  }
}

// .no-item-to-view-container {
//   background-color: $logo-color;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   padding: 300px;

//   .no-item-to-view {
//     background-color: $dark-color;
//     padding: 200px 400px;
//     border-radius: 20px;
//     color: $Primary-color;
//     white-space: nowrap;
//     display: flex;
//     flex-direction: column;
//     font-size: 30px;
//     font-weight: bold;
//     font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
//       Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
//       sans-serif;

//     .no-item-to-view-back-btn {
//       width: 100%;
//       text-align: center;
//       font-size: 15px;
//       margin-top: 10px;
//       color: $Primary-color;
//     }
//   }
// }

// @media (max-height: 900px) {
//   .view-out-container {
//     .view-navbar {
//       img {
//         width: 320px;
//         height: 100px;
//       }
//     }
//   }
// }

// @media (max-width: 1300px) {
//   .view-out-container {
//     width: 100%;
//     height: 100%;
//     background-color: $dark-color;
//     color: $white-color;
//     .view-navbar {
//       width: 100% !important;
//       display: flex !important;

//       img {
//         width: 200px;
//         margin-left: 20px;
//         margin-bottom: 10px;
//         height: 70px;
//       }

//       .back-from-view-container {
//         position: absolute;
//         left: 0;
//         margin-left: 10px;
//       }

//       .view-cart-btn {
//         font-size: 25px;
//         top: 50px;
//         right: -20px;
//       }
//     }

//     .view-container {
//       display: flex;
//       height: 100%;
//       //   flex-direction: column;

//       .view-images {
//         padding: 0px;
//         flex: 1;
//         width: 50%;

//         img {
//           width: 250px !important;
//           height: 250px !important;
//           object-fit: fill;
//         }
//       }

//       .view-details-container {
//         padding: 20px 5px;
//         flex: 1;

//         .view-details-title {
//           margin-left: 5px;

//           .view-details-description {
//             margin-bottom: 10px;
//           }

//           p {
//             margin-bottom: 5px;
//           }
//         }
//       }

//       .add-to-cart-button {
//         font-size: 17px !important;
//       }
//     }
//   }

//   .bg-slider-in-view {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 35px;
//     // background-color: red;
//   }

//   .swiper-backface-hidden .swiper-slide {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     // margin-bottom: 10px;
//   }

//   .view-out-container .view-container .view-images img {
//     border: none;
//   }

//   .view-img-of-swiper {
//     border-radius: 20px !important;
//     object-fit: fill !important;
//     width: 100% !important;
//   }
// }
