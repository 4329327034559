@import "../../assets/style/vars.scss";

.About {
  background-color: $dark-color;
}
section {
  min-height: 100vh;
  /* height: 100vh; */
}
.contanier {
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 30px 0;
}
.text_header {
  width: 100%;
  text-align: center;
}
.text_header h1 {
  font-size: 45px;
  color: $logo-color;
  margin-bottom: 15px;
  letter-spacing: 10px;
  text-transform: uppercase;
  position: relative;
  width: max-content;
  margin: auto auto 10px auto;
}
.text_header h1::before {
  position: absolute;
  content: "";
  bottom: 50%;
  width: 50px;
  height: 2px;
  left: -50px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: $logo-color;
}
.text_header h1::after {
  position: absolute;
  content: "";
  bottom: 50%;
  width: 50px;
  height: 2px;
  right: -90px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: $logo-color;
}
.text_header p {
  font-size: 16px;
  letter-spacing: 10px;
  font-weight: 500;
  color: $white-color;
  opacity: 0.7;
}
@media (max-width: 600px) {
  .text_header p {
    font-size: 14px;
    max-width: 100%;
    line-height: 2;
    letter-spacing: 5px;
    padding-bottom: 30px;
  }
}

.about_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .about_body {
    margin: 20px 0 30px 0;
    flex-direction: column-reverse;
  }
}
.about_body .content {
  max-width: 500px;
}
.about_body .content p {
  font-size: 18px;
  color: $white-color;
  font-family: "El Messiri", sans-serif;
}
@media (max-width: 600px) {
  .about_body .content {
    margin-top: 20px;
  }
}
.about_body .content p label {
  font-size: 35px;
  color: $logo-color;
  background: radial-gradient(
    circle farthest-side at center center,
    $logo-color-2 0%,
    $logo-color 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "El Messiri";
}
.about_body .image {
  max-width: 550px;
}
.about_body .image img {
  width: 100%;
}
