@import "../../../assets/style/vars.scss";

.add-to-cart-button {
  background-color: $logo-color;
  color: $dark-color;
  border: none;
  padding: 10px 20px;
  width: max-content;
  // height: 55px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  // &:hover {
  //   box-shadow: 5px 5px 20px $logo-color-2, -6px -6px 15px $dark-color;
  // }

  &.added {
    background-color: $logo-color;
    animation: addedEffect 0.5s forwards;
  }
}

@keyframes addedEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
