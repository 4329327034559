@import "../../assets/style/vars.scss";

.rings-main-container {
  padding: 20px;
  text-align: center;
  background-color: $dark-color;
  // border-bottom: 2px solid $silver;
  display: flex;
  flex-direction: column;

  .earring-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 45px;
    // margin-top: 30px;

    h2 {
      position: relative;
      text-align: center;
      align-items: center;
      font-size: 35px;
      color: $logo-color;
      border-radius: 30px;
      padding: 10px;
      margin-bottom: 0;
      text-shadow: $text-shadow;
      font-family: "KapraNeue", "Brandon Grotesque", sans-serif;
      text-transform: uppercase;
    }
    h2::before {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      left: -70px;
      bottom: 50%;
      background-color: $logo-color;
      border-radius: 50%;
    }
    h2::after {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      right: -70px;
      bottom: 50%;
      border-radius: 50%;
      background-color: $logo-color;
    }
  }
  .earring-section {
    margin-top: 30px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
  }
  h1 {
    margin-bottom: 30px;
    font-size: 50px;
    // color: $logo-color;
    color: $silver;
    margin-top: 30px;
  }
}

@media (max-width: $mobile-bp) {
  .rings-main-container {
    padding-left: 0px;
    padding-right: 0px;
    // height: 100vh;
    scroll-snap-align: start;

    .earring-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 45px;
      // margin-top: 30px;

      h2 {
        font-size: 22px;
      }
      h2::before {
        width: 30px;
        left: -30px;
      }
      h2::after {
        width: 30px;
        right: -30px;
      }
    }
    h1 {
      font-size: 30px;
      margin-top: 100px;
      margin-bottom: 40px;
    }
  }
}
