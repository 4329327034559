@import "../../../assets/style/vars.scss";

.bracelets-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  // margin-bottom: 20px;
  font-size: 2rem;
  color: $silver;
  text-transform: uppercase;
}

.bracelets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  .bracelets-card {
    // background: $dark-color;
    background: #f0f0f0;
    color: $dark-color;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    padding: 10px 20px;
    width: 300px;
    cursor: pointer;
    // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    // transition: transform 0.2s;
    transition: box-shadow 0.3s ease;
    position: relative;
    &:hover {
      box-shadow: 0 0 10px $logo-color,
        0 0 20px $logo-color, 0 0 30px $logo-color,
        0 0 40px $logo-color;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    &::before {
      top: -50px;
      left: -50px;
      background: radial-gradient(
        circle at 30% 30%,
        $logo-color,
        transparent
      );
      transform: scale(0);
    }
    &::after {
      bottom: -50px;
      right: -50px;
      background: radial-gradient(
        circle at 70% 70%,
        $logo-color,
        transparent
      );
      transform: scale(0);
    }
    &:hover::before,
    &:hover::after {
      transform: scale(1);
    }
    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
      border-radius: 20px;
      transition: all 0.3s;
    }
    img:hover {
      // transform: scale(0.9);
    }

    .bracelets-details {
      text-align: center;
      margin-top: 10px;

      .price {
        font-size: 18px;
        letter-spacing: 8px;
        color: $logo-color;
        margin: 20px 0;
        font-weight: 600;
      }

      .title {
        color: $dark-color;
        background-color: transparent;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: "Playfair Display", sans-serif;
      }

      .description {
        display: none;
        font-size: 1rem;
        color: $silver;
        margin: 5px 0;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .bracelets-loading {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    // height: 50vh;
  }

  .bracelets-container {
    gap: 10px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;

    .bracelets-card {
      width: 80%;
      margin: auto;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        transform: none;
      }

      img {
        width: 80%;
        padding: 10px 20px;
        border-radius: 12px;
      }

      .bracelets-details {
        .title {
          text-align: center;
          font-size: 1.2rem;
          margin-right: 0;
        }

        .description {
          display: none;
        }

        .price {
          display: block;
        }
      }
    }
  }
}
