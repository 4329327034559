@import '../../assets/style/vars.scss';

.price-filter-container {
    &.rtl {
      direction: rtl;
    }
  
    &.ltr {
      direction: ltr;
    }
  }

.price-filter-container {
    display: flex;
    align-items: center !important;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    width: 100%;
    

    .price-filter-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95% !important;

        .price-filter-check-boxes {
            display: flex;
            justify-content: space-around;
            font-size: 16px;
            flex: 3;
            margin-right: 40px;


        }

        .custom-price-filter {
            flex: 1;
            display: flex;

            .custom-price-input-first {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100% !important;
            }

            .custom-price-input-second {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            .filter-button {
                display: flex;
                justify-content: center;

                button {
                    padding: 10px 20px;
                    background-color: #35353f;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                &:hover {
                    background-color: #e7bb67;
                }
            }
        }

        input {
            margin: 0 10px;
            padding: 10px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 5px;
        }

    }

    .currency-selector {
        margin-top: 20px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        position: relative;


    }

    .cureency-options{
        width: 100%;
    }
    .css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
        width: 100% ;
    }





}

@media (max-width: 1420px) {
    .price-filter-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .price-filter-inputs {
            display: flex;
            flex-direction: column;

            .price-filter-check-boxes {
                display: flex;
                flex-direction: column;
                margin: 0;
                margin-bottom: 5px;
            }

            .custom-price-filter {
                display: flex;
                flex-direction: column;

                input {
                    padding: 5px;
                }

                .custom-price-input-first {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    label {
                        flex: 1;
                    }

                    input {
                        flex: 1;
                    }
                }

                .custom-price-input-second {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    label {
                        flex: 1;
                    }

                    input {
                        flex: 1;
                    }
                }

                .filter-button {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                }
            }
        }
    }

    .currency-selector {
        width: 30% !important;
    }
}