@import "../../assets/style/vars.scss";

.trending-container {
  &.rtl {
    direction: rtl;
  }

  &.ltr {
    direction: ltr;
  }
}

.trending-out-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-top: 40px;
  position: relative;
  // height: 100vh;
  // background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
  background: $dark-color;
  padding: 30px 0;

  &::before {
    position: absolute;
    top: 0;
    width: 100% !important;
    height: 30px;
    z-index: 2;
    content: "";
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      $dark-color 100%
    );
  }

  .trending-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 45px;
    // margin-top: 30px;

    h2 {
      position: relative;
      text-align: center;
      align-items: center;
      font-size: 35px;
      color: $logo-color;
      border-radius: 30px;
      padding: 10px;
      margin-bottom: 0;
      text-shadow: $text-shadow;
      font-family: "KapraNeue", "Brandon Grotesque", sans-serif;
      text-transform: uppercase;
    }
    h2::before {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      left: -70px;
      bottom: 50%;
      background-color: $logo-color;
      border-radius: 50%;
    }
    h2::after {
      position: absolute;
      width: 60px;
      height: 2px;
      content: "";
      right: -70px;
      bottom: 50%;
      border-radius: 50%;
      background-color: $logo-color;
    }
  }

  .trending-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .trending-swiper {
    margin-top: 30px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0px !important;
      cursor: pointer;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: $white-color;
      color: $dark-color;
      border-radius: 50%;
      padding: 25px;

      &::after {
        font-size: 30px;
      }
    }

    .swiper-button-next::after {
      margin-left: 5px;
    }

    .swiper-button-prev::after {
      margin-right: 5px;
    }
  }

  .swiper-wrapper {
    // margin-bottom: 50px;
    padding: 0 40px;
  }

  .trending-card {
    position: relative;
    z-index: 2;
    width: 300px;
    // height: 450px;
    background-color: #f0f0f0;
    border-radius: 5px !important;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .trending-product-img {
    // height: 55%;
    background-repeat: no-repeat;
    background-position: 0 -75px;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }
  }

  .trending-details {
    width: 100%;
    height: 45%;
    // background-image: url("../../page/image/background-category.jpg");
    // background-size: auto;
    // background-attachment: fixed;
    // border-radius: 0 0 5px 5px;
    // padding: 0.8rem;
    color: $logo-color;
    margin-bottom: 5px;
    h2 {
      color: $dark-color;
      background-color: transparent;
      text-align: center;
      font-weight: 700;
      font-size: 32px;
      letter-spacing: 5px;
      text-transform: uppercase;
      font-family: "Playfair Display", sans-serif;
    }

    p {
      font-size: 0.8rem;
      margin-top: 15px;
      font-size: 18px;
    }

    .youtube-buttons {
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .youtube-price {
      font-weight: 500;
      font-size: 18px;
      margin-top: 10px;
      letter-spacing: 10px;
      text-align: center;
    }

    .youtube-btn {
      position: relative;
      border: none;
      outline: none;
      background-color: $logo-color;
      color: $dark-color;
      height: 40px;
      border-radius: 50px;
      font-size: 0.9rem;
      cursor: pointer;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   border: 1px solid #35353f;
      //   height: 45px;
      //   transform: 0.3s;
      // }

      // &:hover::before {
      //   border-color: #fff;
      // }
    }

    .youtube-cart {
      width: 160px;

      &::before {
        width: 165px;
        border-radius: 50px;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .trending-out-container {
    scroll-snap-align: start;
    height: 100vh;
    .trending-title {
      h2 {
        font-size: 22px;
      }
      h2::before {
        width: 40px;
        left: -40px;
      }
      h2::after {
        width: 40px;
        right: -40px;
      }
    }
    .trending-swiper {
      .first-gallery-card-in-swiper {
        margin-left: 0px !important;
      }
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0px !important;
      cursor: pointer;
      width: 100%;
    }
    // .swiper-wrapper {
    //     margin-bottom: 50px;
    //     padding: 0;
    //     // width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    .swiper-wrapper {
      padding: 0;
      margin-bottom: 10px;
    }
  }
}
