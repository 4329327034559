@import "../../assets/style/vars.scss";

.checkout {
  height: 100vh;
  border-radius: 0 !important;
  width: 100%;
  .container {
    width: 80%;
    height: 100vh;
    margin: auto;
    padding: 40px 0;
    .header {
      display: flex;
      margin-bottom: 20px;
      transition: all 0.3s;
      width: max-content;
      padding: 10px 12px;
      border-radius: 12px;
      background-color: $logo-color-2;
      cursor: pointer;
      h2 {
        font-size: 15px;
        color: $logo-color;
        text-transform: none;
      }
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: $logo-color;
        margin-right: 20px;
      }
    }
    hr {
      color: $logo-color;
      border-radius: 50%;
      margin-bottom: 20px;
    }
    .body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      gap: 50px;
      .left {
        width: 100%;
        height: 100%;
        .body_left {
          height: 80%;

          h2 {
            font-size: 25px;
            color: $white-color;
            letter-spacing: 2px;
            text-transform: none;
            margin-bottom: 20px;
          }
          .box_cart {
            height: 90%;
            overflow: scroll;
            scrollbar-width: none;
          }
          .cart {
            margin-bottom: 12px;
            padding: 10px 15px;
            height: 100px;
            background-color: #f0f0f0;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            .imgae_cart {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 10%;
              img {
                width: 100%;
                border-radius: 10px;
              }
            }
            .conten_cart {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex: 0.9;
              h2 {
                margin-bottom: 0;
                color: $logo-color;
                text-align: center;
                font-weight: 700;
                font-size: 30px;
                letter-spacing: 5px;
                text-transform: uppercase;
                font-family: "Playfair Display", sans-serif;
              }
              p {
                color: $dark-color;
                opacity: 0.5;
                // flex: 0.8;
                font-size: 14px;
              }
            }
          }
        }
      }
      .right {
        width: 65%;
        height: 100%;
        // background-color: $old-logo-color;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        color: $white-color;
        border-radius: 10px;
        padding: 20px;
        .discount {
          color: $logo-color;
          font-size: 35px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .checkout {
    height: auto !important;
    min-height: 100vh !important;
  }
  .checkout .container {
    height: auto !important;
    width: 90% !important;
  }
  .body {
    flex-direction: column;
    .right {
      width: 100% !important;
    }
  }
  .checkout-out-container {
    height: auto !important;
  }
}
.checkout-container {
  height: 100vh;
  background: $dark-color;
}
.checkout-out-container {
  //   height: 100vh;
  width: 100%;
  background-color: $dark-color;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .checkout-left-title {
    border-bottom: 1px solid $dark-color;
    font-weight: 600;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 20px;
    margin-bottom: 35px;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent;
  }
  .react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: 1px solid $logo-color !important;
  }
  .flag-dropdown.open {
    background-color: transparent !important;
  }
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
  }
  .left-input-container {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    .react-tel-input .form-control {
      background-color: transparent !important;
    }
    .p-error {
      font-size: 15px;
      margin-top: 3px;
    }

    .p-inputtext {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      //   margin-bottom: -10px;
    }

    .p-component {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .react-tel-input {
      display: flex;
      justify-content: start;
      align-items: center;
      //   margin-left: 30px;

      .form-control {
        width: 100% !important;
        height: 100%;
        padding: 10px;
        font-size: 16px;
        border-radius: 0 10px 10px 0;
        border: 2px solid $logo-color;
        color: $white-color;
        margin-left: 37px;
      }
    }

    .currency-select-container {
      width: 100%;
      margin-top: 20px;
      cursor: pointer;

      .currency-select {
        cursor: pointer;
      }

      label {
        display: block;
        margin-bottom: 10px;
        font-size: 14px;
        color: $white-color;
      }

      .currency-select {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid $logo-color;
        background-color: transparent;
        color: $white-color;
        cursor: pointer;
        option {
          background-color: $dark-color !important;
        }
      }
    }
  }

  //   .checkout-left-container {
  //     height: auto;
  //     width: 100%;
  //     margin-right: 30px;
  //     background-color: $silver;
  //     padding: 30px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  //     border-radius: 10px;

  //   }
}

.swiper-pagination-bullet-active {
  background-color: $dark-color !important;
}

.checkout-right-container {
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 15px 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  height: auto;
  width: 50%;
  border-radius: 10px;

  .bg-slider {
    width: 85%;

    .checkout-right-item {
      display: flex;
      margin-bottom: 10px;
      z-index: 1;
      width: 100%;

      .checkout-right-images {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 70px;
        margin-bottom: 20px;

        img {
          width: 180px;
          height: 180px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .checkout-right-details {
        display: flex;
        justify-content: center;

        .checkout-right-details-title {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          //   margin-bottom: 10px;

          h2 {
            color: $logo-color;
            background-color: transparent;
            text-align: center;
            font-weight: 700;
            font-size: 32px;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-family: "Playfair Display", sans-serif;
          }

          p {
            margin: 0;
            font-size: 20px;
            color: $silver;
          }
        }

        .checkout-right-details-description {
          display: none;
          margin: 0;
          font-size: 14px;
          color: #666;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.card {
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.coupons {
  border-radius: 7px;
  background-color: $dark-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  padding: 5px;

  .p-error {
    margin-left: 18px !important;
  }
}

.coupons_box form {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 10px;
  //   padding: 10px;
  margin-bottom: 10px;
}
.coupons_box {
  position: relative;
}
.coupons_box .X {
  position: absolute;
  top: -10px;
  right: 0;
  background-color: $logo-color;
  color: $dark-color;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.input_field {
  width: auto;
  height: 36px;
  padding: 0 0 0 12px;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: none;
  //   box-shadow: 0px 0px 0px 2px #242424;
  //   background-color: transparent;
}

.coupons_box form button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background-color: $logo-color;
  color: $dark-color;
  //   background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
  //   box-shadow: 0px 0.5px 0.5px #efefef, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 5px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

/* Checkout */
.checkout {
  border-radius: 12px;
  background-color: $dark-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  //   padding: 10px 20px;
  .title {
    // margin: 20px 0 10px 20px;
  }
}

.checkout .details {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 10px;
  gap: 5px;
}

.checkout .details span {
  font-size: 13px;
  font-weight: 600;
}

.checkout .details span:nth-child(odd) {
  font-size: 11px;
  font-weight: 700;
  color: #707175;
  margin: auto auto auto 0;
}

.checkout .details span:nth-child(even) {
  font-size: 13px;
  font-weight: 600;
  color: #47484b;
  margin: auto 0 auto auto;
}

.checkout .checkout--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  background-color: #efeff3;
}

.price {
  position: relative;
  font-size: 22px;
  color: #2b2b2f;
  font-weight: 900;
}

.price sup {
  font-size: 13px;
}

.price sub {
  width: fit-content;
  position: absolute;
  font-size: 11px;
  color: #5f5d6b;
  bottom: 5px;
  display: inline-block;
}

.checkout .checkout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 36px;
  background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
  box-shadow: 0px 0.5px 0.5px #efefef, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 7px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

  &:hover {
    cursor: pointer;
  }
}

// @media (max-width: $laptop-bp) {
//   .checkout-out-container {
//     display: flex;
//     height: 160vh;
//     margin-top: 70px;

//     .checkout-left-title {
//       margin-bottom: 0 !important;
//     }

//     .checkout-left-container {
//       width: 90%;
//       margin-top: 40px;
//       margin-right: 0;
//       display: flex !important;
//       justify-content: center;
//       gap: 20px;
//       padding: 15px 0 5px 0;

//       .left-input-container {
//         // margin-bottom: 15px !important;
//         // margin-top: 10px;
//         gap: 20px;
//         width: 100% !important  ;
//         height: auto;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         .p-inputtext {
//           width: 100% !important;
//         }

//         .p-component {
//           width: 100% !important;
//         }
//         .form-control {
//           margin-right: 37px;
//         }

//         .react-tel-input {
//           width: 70% !important;
//           margin-bottom: 0px !important;
//         }

//         .p-error {
//           margin-top: 7px !important;
//         }

//         .currency-select-container {
//           width: 90% !important;
//           margin-top: 2px !important;
//           cursor: pointer;

//           label {
//             margin-bottom: 3px !important;
//           }
//         }
//       }
//     }

//     .checkout-right-container {
//       width: 90%;
//       display: flex;
//       justify-content: center !important;
//       align-items: center;
//       margin-top: 50px;
//       margin-bottom: 40px;

//       .bg-slider {
//         width: 100%;

//         .checkout-right-item {
//           flex: 1 !important;
//           margin: 0;
//           margin-bottom: 50px;

//           .checkout-right-images {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             margin: 0;
//             margin-bottom: 20px;
//             margin-top: 20px;
//             // width: 500px ;

//             img {
//               width: 180px;
//               height: 180px;
//               object-fit: cover;
//               border-radius: 8px;
//             }
//           }
//         }
//       }

//       .checkout-right-button {
//         flex: 2 !important;
//         width: 100%;
//         display: flex;
//         margin-top: 20px;
//         justify-content: center;
//         align-items: center;

//         .card {
//           width: 80%;
//           background: #ffffff;
//           box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
//             0px 105px 63px rgba(0, 0, 0, 0.05),
//             0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1),
//             0px 0px 0px rgba(0, 0, 0, 0.1);
//           margin-bottom: 10px;
//           padding: 5px;

//           .p-error {
//             margin-left: 19px;
//           }
//         }

//         .checkout {
//           // margin-bottom: 50px;
//         }
//       }
//     }
//   }
// }
