@import "../../assets/style/vars.scss";

.Contact {
  background-color: $dark-color;
  color: $white-color;
}
.Contact .contanier {
  width: 80%;
  margin: auto;
  padding: 20px 0;
}
.Contact .contanier .text_header {
  text-align: center;
}
.Contact .contanier .text_header p {
  opacity: 0.6;
}
.Contact .contanier .text_header h1 {
  font-size: 35px;
  color: $logo-color;
  letter-spacing: 5px;
  position: relative;
  width: max-content;
  margin: auto;
  text-transform: uppercase;
}
.Contact .contanier .text_header h1::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: -50px;
  bottom: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: $logo-color;
}
.Contact .contanier .text_header h1::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  right: -100px;
  bottom: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: $logo-color;
}
.Contact_body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}
.Contact_body .social_madia ul {
  list-style: none;
}
.Contact_body .social_madia ul li {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: $white-color;
  margin-bottom: 20px;
}
.Contact_body .social_madia ul li i {
  color: $logo-color;
  font-size: 30px;
  margin-right: 25px;
}
.Contact_body .social_madia ul li p {
  font-size: 20px;
  letter-spacing: 2px;
  font-family: "El Messiri";
  opacity: 0.8;
}
.Contact_body .social_madia .Icon .wrapper {
  display: inline-flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding-top: 40px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
}
.Contact_body .social_madia .Icon {
  margin-top: -30px;
}
.Contact_body .social_madia .Icon .wrapper .icon {
  position: relative;
  background: transparent;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.Contact_body .social_madia .Icon .wrapper .icon svg {
  background-color: transparent;
}
.Contact_body .social_madia .Icon .wrapper .tooltip {
  position: absolute;
  white-space: nowrap;
  top: 0;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Contact_body .social_madia .Icon .wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Contact_body .social_madia .Icon .wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.Contact_body .social_madia .Icon .wrapper .icon:hover span,
.Contact_body .social_madia .Icon .wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.Contact_body .social_madia .Icon .wrapper .facebook:hover,
.Contact_body .social_madia .Icon .wrapper .facebook:hover .tooltip,
.Contact_body .social_madia .Icon .wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.Contact_body .social_madia .Icon .wrapper .twitter:hover,
.Contact_body .social_madia .Icon .wrapper .twitter:hover .tooltip,
.Contact_body .social_madia .Icon .wrapper .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #fff;
}

.Contact_body .social_madia .Icon .wrapper .instagram:hover,
.Contact_body .social_madia .Icon .wrapper .instagram:hover .tooltip,
.Contact_body .social_madia .Icon .wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #fff;
}

.Contact_body .form {
  flex: 0.9;
}
.Contact_body .form form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
.Contact_body .form form .contact-button {
  padding: 10px 35px;
  background-color: $logo-color;
  border-radius: 30px;
  font-size: 18px;
}
.Contact_body .form .card:nth-child(1),
.Contact_body .form .card:nth-child(2) {
  width: 49%;
}
.Contact_body .form .card:nth-child(3) {
  width: 100%;
}
.p-float-label:has(input:focus) label,
.p-float-label:has(input.p-filled) label,
.p-float-label:has(input:-webkit-autofill) label,
.p-float-label:has(textarea:focus) label,
.p-float-label:has(textarea.p-filled) label,
.p-float-label:has(.p-inputwrapper-focus) label,
.p-float-label:has(.p-inputwrapper-filled) label {
  top: 0.25rem !important;
  left: 20px;
  background-color: $dark-color;
  color: $white-color;
  padding: 0 5px;
  opacity: 1;
}
.p-float-label {
  background-color: $dark-color;
  //   border-radius: 10px;
}
.p-float-label label {
  top: 35% !important;
  color: $white-color;
  opacity: 0.4;
}
.p-float-label > label {
  left: 1.35em !important;
}
.p-inputtext {
  //   margin: 10px 0;
  padding: 15px 15px;
  color: $white-color;
  background-color: transparent;
  border: 2px solid $logo-color;
  font-weight: 400;
  border-radius: 10px !important;
  width: 100%;
}
.p-icon-field > .p-input-icon {
  top: 35%;
}
.p-inputtext:enabled:hover {
  border-color: $logo-color !important;
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: $logo-color !important;
}

.contact-container {
  &.rtl {
    direction: rtl;
  }

  &.ltr {
    direction: ltr;
  }
}

.contact-phone {
  direction: ltr;
}

.contact-out-container {
  display: flex;
  height: 90vh;
  // background-color: $dark-color;
  flex-direction: column;
  height: 90vh !important;

  .contact-top-container {
    position: relative;
    // padding-top: 100px;
    display: flex;
    flex: 2.5;

    .contact-left-side {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-left: 50px;
      margin-right: 50px;

      img {
        width: 550px;
        height: 180px;
      }
    }

    .contact-right-side {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-form {
        display: flex;
        flex-direction: column;
        width: 80%;
        background-color: $silver;
        padding: 20px;
        border-radius: 10px;

        .text-field {
          margin-top: 20px;
          border-color: $Primary-color;
          background-color: $dark-color;
          color: $white-color !important;
          border-radius: 15px;

          .MuiInputBase-root {
            background: $white-color;
            backdrop-filter: blur(30px);
          }

          .MuiOutlinedInput-root {
            & fieldset {
              border-radius: 5px;
              border-color: $dark-color;
            }

            &.Mui-focused-fieldset {
              border-color: $dark-color;
            }
          }

          .MuiInputLabel-root {
            color: $dark-color;
            font-weight: 600;
          }
        }

        .last-text-field {
          margin-bottom: 20px;
        }

        .contact-button {
          position: relative;
          display: inline-block;
          background: linear-gradient(to left, #7d7e7d 0%, #0e0e0e 100%);
          color: $white-color;
          font-family: "Segoe UI", sans-serif;
          font-weight: bold;
          font-size: 18px;
          border: none;
          width: 100%;
          // white-space: nowrap;
          border-radius: 30px;
          padding: 14px 28px;
          cursor: pointer;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
          animation: button-shimmer 2s infinite;
          transition: all 0.3s ease-in-out;

          &:hover {
            background: linear-gradient(to right, #7d7e7d 0%, #0e0e0e 100%);
            animation: button-particles 1s ease-in-out infinite;
            transform: translateY(-2px);
          }

          &:active {
            transform: scale(0.95);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
          }
        }

        /* Shimmer animation */
        @keyframes button-shimmer {
          0% {
            background-position: left top;
          }

          100% {
            background-position: right bottom;
          }
        }

        /* Particle animation */
        @keyframes button-particles {
          0% {
            background-position: left top;
          }

          100% {
            background-position: right bottom;
          }
        }
      }
    }
  }

  .contact-bottom-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $silver;
    font-size: 20px;
    flex-direction: column;
    margin-bottom: 30px;

    .contact-location {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;

      .pi-map-marker {
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .contact-phone {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;

      .pi-phone {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .Contact_body {
    flex-direction: column;
    scroll-snap-align: start;
    margin-top: 30px;
  }
  .Contact .contanier {
    width: 90%;
  }
  .Contact .contanier .text_header h1 {
    font-size: 22px;
  }
  .Contact .contanier .text_header p {
    margin: 10px auto !important;
    font-size: 10px;
    max-width: 90% !important;
  }
  .Contact_body .social_madia ul li p {
    font-size: 16px;
    // white-space: nowrap;
  }
  .Contact_body .form .card:nth-child(1),
  .Contact_body .form .card:nth-child(2) {
    width: 100%;
  }
  .contact-out-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // height: 90vh !important;
    scroll-snap-align: start;

    .contact-top-container {
      // flex: ;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-left-side {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        img {
          width: 90% !important;
          // width: 320px !important;
          height: 100px !important;
          object-fit: contain;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .contact-right-side {
        width: 100%;
        margin-bottom: 10px;

        .contact-form {
          width: 80%;
          padding: 5px 20px;
          border-radius: 5px;

          .text-field {
            padding: 0 !important;

            .MuiInputBase-root {
              background: $white-color;
              backdrop-filter: blur(30px);
              height: 48px;
              display: flex;
              justify-content: center !important;
              align-items: center !important;
            }

            .MuiInputBase-input {
              padding: 5px;
              display: flex;
              justify-content: center !important;
              align-items: center !important;
            }

            .MuiOutlinedInput-root {
              & fieldset {
                border-radius: 5px;
                border-color: $dark-color;
                padding: 5px !important;
                display: flex;
                justify-content: center !important;
                align-items: center !important;
              }

              &.Mui-focused-fieldset {
                border-color: $dark-color;
                padding: 5px !important;
              }
            }

            .MuiInputLabel-root {
              color: $dark-color;
              font-weight: 600;
            }
          }
        }
      }
    }

    .contact-bottom-container {
      width: 100%;

      .contact-location {
        font-size: 15px;
      }

      .contact-phone {
        font-size: 15px;
      }

      .contact-details {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}
