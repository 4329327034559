@import "../../assets/style/vars.scss";

.Logos {
  overflow: hidden;
  padding: 10px 0;
  background-color: $dark-color;
  white-space: nowrap;
  position: relative;
}
.Logos::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
  content: "";
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 10%,
    #e7bb67 200%
  );
}
.Logos::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
  content: "";
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 10%,
    #e7bb67 200%
  );
}
.Logos_slide {
  display: inline-block;
  white-space: nowrap;
  background-color: transparent;
  animation: 30s slider infinite linear;
}
.Logos_slide img {
  height: 40px;
  margin: 0 40px;
  background-color: transparent;
}
@keyframes slider {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
