@import '../../../assets/style/vars.scss';

.back-from-view-btn {
    display: flex;
    height: 3em;
    width: 100px;
    align-items: center;
    justify-content: center;
    background-color: $Primary-color !important;
    color: $dark-color;
    border-radius: 3px;
    letter-spacing: 1px;
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: $dark-color;
   }
   
   .back-from-view-btn > svg {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 20px ;
    fill: $dark-color;
    font-weight: bold !important;
    transition: all 0.4s ease-in;

}

.back-from-view-btn:hover > svg {
    font-size: 1.2em;
    transform: translateX(-5px);
}

   
   .back-from-view-btn:hover {
    box-shadow: 5px 5px 20px $Primary-color, -6px -6px 15px $logo-color;
    transform: translateY(-2px);
   }

   @media (max-width: $mobile-bp){
    .back-from-view-btn{
        width: 80px;
        padding: 5px;
    }
   }