@import "../../../assets/style/vars.scss";

.necklaces-card-section {
  margin-top: 30px;
  .necklace-loading {
    display: flex;
    justify-content: center;
  }

  .necklaces-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .necklaces-card {
    background: #f0f0f0;
    color: #231f20;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    padding: 10px 15px;
    width: 300px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 20px;
      transition: all 0.3s;
    }
    // img:hover {
    //   transform: scale(1.1);
    // }
    .necklaces-card-details-container {
      text-align: center;
      margin-top: 10px;
      width: 100%;
      display: flex;

      .necklaces-card-details {
        flex: 5;

        .title {
          color: $dark-color;
          background-color: transparent;
          text-align: center;
          font-weight: 700;
          font-size: 32px;
          letter-spacing: 5px;
          text-transform: uppercase;
          font-family: "Playfair Display", sans-serif;
        }

        .description {
          font-size: 22px;
          color: $dark-color;
          margin: 5px 0;
        }

        .price {
          font-size: 18px;
          letter-spacing: 8px;
          color: $logo-color;
          margin: 20px 0;
          font-weight: 600;
          text-align: center;
        }
      }

      .necklaces-card-plus-container {
        display: flex;
        justify-content: center;
        align-items: end;
        margin-bottom: 20px;
        flex: 1;

        .necklaces-card-plus {
          border: 1px solid $dark-color;
          font-size: 25px;
          font-weight: bold;
          padding: 20px;
          color: $dark-color;
          border-radius: 50%;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .necklaces-card-section {
    .necklace-loading {
      display: flex;
      justify-content: center;
      // height: 50vh;
      align-items: center;
    }
    .necklaces-card-container {
      gap: 10px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
    }
    .necklaces-card {
      width: 80%;
      margin: auto;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        transform: none;
      }

      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
      }

      .necklaces-card-details-container {
        .necklaces-card-details {
          .title {
            text-align: center;
            font-size: 1.2rem;
            margin-right: 0;
          }
          .description {
            display: none;
          }
          .price {
            // display: none;
          }
        }
      }
    }
  }
}
