@import '../../assets/style/vars.scss';

.category-container{
  &.rtl {
    direction: rtl;
  }

  &.ltr {
    direction: ltr;
  }
}

.Home {
  height: 100vh;
  background-image: url("../image/background-category.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}

.Home::before {
  position: absolute;
  content: "";
  opacity: 0.7  ;
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.Home .Home_content {
  width: 80%;
  margin: auto;
  padding-top: 150px;
  background-color: transparent !important;
}

.Home .bg-slider {
  position: relative;
  z-index: 77;
  width: 100%;
  background-color: transparent;
}

.Home .bg-slider .swiper-wrapper {
  background-color: transparent;  
}

.Home .bg-slider .swiper-wrapper .swiper-slide {
  position: relative;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.Home .bg-slider .swiper-wrapper .swiper-slide .item_img {
  width: 20%;
  position: relative;
  background-color: transparent;
  flex: 0.5;
}

.Home .bg-slider .swiper-wrapper .swiper-slide img {
  width: 100%;
  background-color: transparent;
  object-fit: cover;
}

.Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen {
  color: $white-color;
  background-color: transparent;
  transition: 0.3s ease;
}

.Home .bg-slider .swiper-wrapper .swiper-slide .text-content {
  background-color: transparent;
  top: 25%;
  color: $white-color;
  transition: 0.3s ease;
}

.Home .bg-slider .swiper-wrapper .swiper-slide .text-content .title,
.Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen .title {
  font-size: 18px;
  letter-spacing: 25px;
  background-color: transparent;
  text-shadow: $text-shadow;
  margin-bottom: 10px;
  margin-left: 15px;
  text-transform: uppercase;
  transform: translateY(-50px);
  opacity: 0;
}

.Home .bg-slider .swiper-wrapper .swiper-slide-active {
  margin-right: 0 !important;
}

.Home .bg-slider .swiper-wrapper .swiper-slide-active .text-content .title,
.Home
  .bg-slider
  .swiper-wrapper
  .swiper-slide-active
  .text-content-screen
  .title {
  transform: translateY(0);
  font-size: 25px;
  opacity: 1;
  transition: 2s ease-in-out;
  transition-duration: 1s;
  transition-property: transform, opacity;
}

.category-paragraph-container{
  padding: 5px 15px;
}

.Home .bg-slider .swiper-wrapper .swiper-slide .text-content p,
.Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen p {
  max-width: 700px;
  color: $white-color;
  background-color: rgba(225, 225, 225, 0.1);
  backdrop-filter: blur(5px);
  text-shadow: $text-shadow;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 1.5rem;
  box-shadow: $box-shadow;
  margin-bottom: 30px;
  transform: translateX(80px);
  opacity: 0;
}

.Home .bg-slider .swiper-wrapper .swiper-slide-active .text-content p,
.Home .bg-slider .swiper-wrapper .swiper-slide-active .text-content-screen p {
  transform: translateX(0);
  opacity: 1;
  transition: 2s ease-in-out;
  transition-duration: 1s;
  transition-property: transform, opacity;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.bg-slider-thums {
  position: absolute;
  z-index: 777;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease;
  padding: 10px 15px;
  border-radius: 10px;
  width: 40%;
  overflow-x: scroll;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  background-color: rgba(225, 225, 225, 0.1);
  backdrop-filter: blur(5px);
}

.bg-slider-thums .swiper-slide {
  width: fit-content;
  opacity: 1;
  display: flex;
  background-color: transparent;
  justify-content: center;
}

.bg-slider-thums .swiper-slide img {
  width: 65%;
  height: 130px;
  border-radius: 10px;
  background-color: transparent !important;
  cursor: pointer;
}

.Home .swiper-wrapper {
  background-color: transparent;
}

.Btn {
  width: 160px;
  height: 60px ;
  border: none;
  border-radius: 10px;
  font-size:20px;
  background: linear-gradient(
    to right,
    $color-1,
    $logo-color,
    $color-1,
    $color-1,
    $logo-color,
    $color-1
  );
  background-size: 250%;
  background-position: left;
  color: $logo-color;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
}

.Btn::before {
  position: absolute;
  content: attr(textContent);
  color: $logo-color;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}

.Btn:hover {
  background-position: right;
  transition-duration: 1s;
}

.Btn:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.Btn:active {
  transform: scale(0.95);
}

.categories-btn-div {
  margin-top: 5px;
  margin-left: 15px;
}

@media (max-width: 1900px){
  .bg-slider-thums{
    height: 150px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-slider-thums .swiper-slide img{
    height: 120px !important;
    width: 70% !important;
  }
}

@media (max-width : 1300px) {
  .bg-slider-thums .swiper-slide img {
    width: 80%;
    height: 100px;
  }
}

@media (max-width: 1024px) {
  .Home .bg-slider .swiper-wrapper .swiper-slide-active {
    width: 100%;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide {
    display: flex;
    flex-direction: row-reverse;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide .item_img {
    flex: 1;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen {
    flex: 1;
    width: 100%;
  }
}

// @media (max-width: 768px) {
//   .Home .Home_content {
//     width: 95%;
//     margin: auto !important;
//   }
//   .Home .bg-slider {
//     width: 90%;
//   }
// }

@media (max-width: 800px) {
  .Home {
    background-attachment: scroll;
    opacity: 0.9  ;
    height: 100vh !important;
    scroll-snap-align: start;
  }

  .Home .Home_content {
    width: 95%;
    margin: auto !important;
    padding-top: 20px;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide{
    margin-top: 80px;
  }
  
  .Home .bg-slider .swiper-wrapper .swiper-slide .item_img {
    width: 60%;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide-active {
    flex-direction: column;
    margin-right: 20px !important;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content {
    margin: 0 20px;
    top: 20%;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen {
    margin: 0 20px;
    top: 15%;
  }
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content p,
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen p {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .bg-slider-thums{
    height: 120px ;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-slider-thums .swiper-slide img{
    height: 100px !important;
    width: 70% !important;
  }
  .Home .media-icon {
    position: absolute;
    left: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    top: 70%;
    backdrop-filter: blur(15px);
    border-radius: 10px;
    background-color: rgba(225, 225, 225, 0.1);
  }
  .Home .media-icon a {
    margin: 0;
    padding: 12px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
  }
  .bg-slider-thums .swiper-slide img {
    width: 100%;
    height: 100px !important;
  }

  .Btn{
    margin-top: 30px;
    height: 50px;
    width: 130px;
    font-size: 15px;
  }

  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content .title,
.Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen .title{
  width: 100%;
}
}

@media (max-width: 410px){
  .Home .bg-slider .swiper-wrapper .swiper-slide .text-content .title,
.Home .bg-slider .swiper-wrapper .swiper-slide .text-content-screen .title {
  font-size: 20px;
  letter-spacing: 15px;
  width: 70%;
  text-align: start;
}
}