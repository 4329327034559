@import "../../../assets/style/vars.scss";

.rings-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .rings-section {

//   h2 {
//     margin-bottom: 20px;
//     font-size: 2rem;
//     color: $dark-color;
//     text-transform: uppercase;
//   }

.rings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;

  .ring-card {
    background: #f0f0f0;
    color: $dark-color;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    padding: 10px 20px;
    width: 300px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.2);
    }

    .ring-details {
      text-align: center;
      margin-top: 10px;

      .price {
        font-size: 18px;
        letter-spacing: 8px;
        color: $logo-color;
        margin: 20px 0;
        font-weight: 600;
        text-align: center;
      }

      .title {
        color: $dark-color;
        background-color: transparent;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: "Playfair Display", sans-serif;
      }

      .description {
        font-size: 1rem;
        color: $dark-color;
        margin: 5px 0;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .rings-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 50vh;
  }

  .rings-container {
    gap: 10px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;

    .ring-card {
      width: 80%;
      margin: auto;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        transform: none;
      }

      &:active {
        transform: scale(1.05);
      }

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .ring-details {
        .price {
          // display: none;
        }

        .title {
          text-align: center;
        }

        .description {
          display: none;
        }
      }
    }
  }
}
