@import "../../../assets/style/vars.scss";

.dark-more-btn-container {
  /* width: 100px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.dark-more-btn-container .dark-btn {
  position: relative;
  width: 180px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-more-btn-container .dark-btn a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: $white-color;
  font-weight: bold;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

.dark-more-btn-container .dark-btn:hover a {
  letter-spacing: 3px;
  color: $silver;
}

.dark-more-btn-container .dark-btn a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.dark-more-btn-container .dark-btn:hover a::before {
  transform: skewX(45deg) translate(200px);
}

.dark-more-btn-container .dark-btn::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: $dark-color;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.dark-more-btn-container .dark-btn:hover::before

/*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.dark-more-btn-container .dark-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: $dark-color;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.dark-more-btn-container .dark-btn:hover::after

/*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

@media (max-width: $mobile-bp) {
  .dark-more-btn-container {
    margin-top: 50px;
    margin-bottom: 20px;
    .dark-btn {
      width: 250px;
      height: 50px;
      font-size: 16px;
    }
  }
}
