@import "../../../assets/style/vars.scss";

.earring-section {
  .earring-loading {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: $dark-color;
    text-transform: uppercase;
  }

  .earring-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .earring-card {
    // background: $dark-color;
    background: #f0f0f0;
    color: $dark-color;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    padding: 10px 20px;
    width: 300px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    img {
      width: 100%;
      // height: 380px;
      object-fit: cover;
      border-radius: 20px;
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.2);
    }

    .earring-details {
      text-align: center;
      margin-top: 10px;

      .title {
        color: $dark-color;
        background-color: transparent;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: "Playfair Display", sans-serif;
      }
      .price {
        font-size: 18px;
        letter-spacing: 8px;
        color: $logo-color;
        margin: 20px 0;
        font-weight: 600;
      }

      .description {
        font-size: 1rem;
        color: $silver;
        margin: 5px 0;
      }
    }
  }
}

@media (max-width: $mobile-bp) {
  .earring-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .earring-loading {
      display: flex;
      justify-content: center;
      height: 50vh;
      align-items: center;
    }

    h2 {
      font-size: 30px;
    }

    .earring-container {
      gap: 10px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
    }

    .earring-card {
      width: 80%;
      margin: auto;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        transform: none;
      }

      img {
        width: 80%;
        padding: 10px 20px;
        border-radius: 12px;
      }

      .earring-details {
        .title {
          text-align: center;
          font-size: 1.2rem;
          margin-right: 0;
        }

        .description {
          display: none;
        }

        .price {
          display: block;
        }
      }
    }
  }
}
