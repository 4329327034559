@import "./breakpoint.scss";

$white-color: #fff;
$dark-color: #231f20;
$body-bg-color: #000;
/* --Primary-color: #fdd700; */
$Primary-color: #cab273;
$logo-color: #e7bb67;
$logo-color-2: #5f2b14;
$old-logo-color: #eedf8b;
$gold: #ffd700;
$silver: #c0c0c0;
$green: #00ff00;
$blue: #007fff;
$magneta: #ff00ff;
$color-1: #5f2b14;
/* --navigation-item-hover-color: #ffa500; */

$text-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
$box-shadow: $logo-color 0px 3px 15px 2px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

$mobile-bp: 480px;
$tablet-bp: 768px;
$laptop-bp: 960px;
$screen-bp: 1200px;
